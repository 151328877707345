.headerLogo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
@media (max-width: 1000px) {
  .starImg {
    width: 300px !important;
    top: 650px !important;
    right: 20px;
  }
  .headerLogo {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  .dotCurve,
  .tri,
  .circle,
  .Circle1 {
    display: none;
  }
  .tutor {
    width: 100% !important;
  }

  .imgThumBlog {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .starImg {
    width: 150px !important;
    top: 350px !important;
    right: 20px;
  }
  .headerLogo {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  .dotCurve,
  .tri,
  .circle,
  .Circle1 {
    display: none;
  }

  .heroEnh {
    width: 100% !important;
    margin: 20px 0px !important;
  }

  .tutor {
    width: 100% !important;
  }

  .imgThumBlog {
    width: 100% !important;
  }
}
